import {
  comparing,
  composeComparators,
  dateComparator,
  stringComparator,
} from "../../sorting";
import { differenceInSeconds } from "date-fns";

function duration(startAccessor, endAccessor) {
  return (a, b) => {
    const durationA = Math.abs(
      differenceInSeconds(startAccessor(a), endAccessor(a))
    );
    const durationB = Math.abs(
      differenceInSeconds(startAccessor(b), endAccessor(b))
    );
    if (durationA > durationB) {
      return 1;
    }
    if (durationA < durationB) {
      return -1;
    }
    return 0;
  };
}

export const workshopComparator = composeComparators(
  comparing(dateComparator, (x) => x.startTime),
  duration(
    (x) => x.startTime,
    (x) => x.endTime
  ),
  comparing(stringComparator, (x) => x.name)
);
