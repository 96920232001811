import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

function BootstrapFormField(props) {
  const { id, label, error, value, children, ...rest } = props;

  return (
    <Form.Group controlId={id}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control value={value || ""} isInvalid={!!error.message} {...rest}>
        {children}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {error.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

BootstrapFormField.prototype = {
  error: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  as: PropTypes.elementType,
};

BootstrapFormField.defaultProps = {
  error: {},
};

export default BootstrapFormField;
