import React from "react";
import Container from "react-bootstrap/Container";
import { useEventRegistrantSaver } from "../../hook/useEventRegistrantSaver";
import { registrationStatus } from "../../../event/registrationStatus";
import classes from "./EventDeclineInvitationPage.module.css";
import { Redirect, useHistory } from "react-router-dom";
import SempraButton from "../../component/SempraButton/SempraButton";

// Almost an exact copy of EventCancelRegistrationPage
function EventDeclineInvitationPage({ event }) {
  const history = useHistory();
  const [
    saveRegistration,
    saveRegistrationResource,
  ] = useEventRegistrantSaver();
  const { registration } = event;

  if (registration == null) {
    return <Redirect to={`/events/${event.id}${window.location.search}`} />;
  }

  const onBackButtonClick = () =>
    history.push(`/events/${event.id}${window.location.search}`);

  const onDeclineButtonClick = () => {
    saveRegistration(event, {
      ...registration,
      status: registrationStatus.declined,
    }).then(() => {
      history.push(`/events/${event.id}/declined${window.location.search}`);
    });
  };

  return (
    <Container>
      <h1>{event.name}</h1>
      <p>
        Are you sure you want to decline your invitation to{" "}
        <strong>{event.name}</strong>?
      </p>
      <div className={classes.buttons}>
        <SempraButton
          loading={saveRegistrationResource.status === "loading"}
          onClick={onDeclineButtonClick}
        >
          {saveRegistrationResource.status === "loading"
            ? "Declining Invitation..."
            : "Decline Invitation"}
        </SempraButton>
        <SempraButton outlined onClick={onBackButtonClick}>
          Back
        </SempraButton>
      </div>
    </Container>
  );
}

export default EventDeclineInvitationPage;
