import React from "react";
import Table from "../Table/Table";
import TimeRange from "../TimeRange/TimeRange";
import { groupWorkshopsByStartTime } from "../../shared/groupWorkshopsByStartTime";
import classes from "./EventWorkshops.module.css";

function EventWorkshops({ workshops, ...rest }) {
  const workshopsByStartTime = groupWorkshopsByStartTime(workshops);
  return (
    <div {...rest}>
      <h2 className={classes.heading}>
        Workshop{workshops.length > 1 ? "s" : ""}
      </h2>
      <Table borderless striped responsive>
        <Table.Head>
          <tr>
            <th className={classes.eventWorkshopTimeColumn}>Time</th>
            <th>Workshop Details</th>
          </tr>
        </Table.Head>
        <Table.Body>
          {workshopsByStartTime.map((group, index) => (
            <React.Fragment key={index}>
              {group.values.map((workshop) => (
                <tr
                  key={workshop.id}
                  className={
                    (index & 1) !== 1
                      ? classes.oddRowGroup
                      : classes.evenRowGroup
                  }
                >
                  <td className={classes.collapsedCell}>
                    <TimeRange
                      {...{
                        from: workshop.startTime,
                        to: workshop.endTime,
                      }}
                    />
                  </td>
                  <td>
                    <p className={classes.workshopName}>
                      <strong>{workshop.name}</strong>
                    </p>
                    <p>{workshop.description}</p>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

export default EventWorkshops;
