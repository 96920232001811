import {
  emptyResource,
  errorResource,
  loadedResource,
  loadingResource,
} from "./resourceFactories";

/**
 * @param resource The resource to map the value of
 * @param mapper The value mapper
 * @returns the given resource with the mapper applied to the value
 */
export function mapValue(resource, mapper) {
  return resource.status === "loaded"
    ? loadedResource(mapper(resource.value))
    : resource;
}

/**
 * @param resource The resource to map the error of
 * @param mapper The error mapper
 * @returns the given resource with the mapper applied to the error
 */
export function mapError(resource, mapper) {
  return resource.status === "error"
    ? errorResource(mapper(resource.error))
    : resource;
}

/**
 * Alias for {@see #mapValue}
 */
export const map = mapValue;

/**
 * Creates a composite of the provided resources.
 * If any resource is loading the composite is considered to be loading
 * If any resource is in error the composite is considered to be in error
 * Only if all resources load successfully will the composite be in loaded state
 * The value of the resource returned will be an ordered array
 * where each entry corresponds to the value of the provided resource
 *
 * @param resources The resources to wrap
 */
export function compose(...resources) {
  if (resources.some((x) => x.status === "empty")) {
    return emptyResource();
  }
  if (resources.some((x) => x.status === "loading")) {
    return loadingResource();
  }
  const errorResource = resources.find((x) => x.status === "error");
  if (errorResource != null) {
    return errorResource;
  }
  return loadedResource(resources.map((x) => x.value));
}
