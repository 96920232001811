import { firebaseReference } from "../firebaseReference";
import { remoteConfigDefaults } from "./remoteConfigDefaults";
import { remoteConfigSettings } from "./remoteConfigSettings";

/**
 * firebase.remoteConfig() with common settings
 */
export const remoteConfigReference = ((config) => {
  config.defaultConfig = remoteConfigDefaults;
  config.settings = remoteConfigSettings;
  return config;
})(firebaseReference?.remoteConfig() || {});
