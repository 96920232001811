import React from "react";
import { Dropdown, Figure } from "react-bootstrap";
import {
  firebaseReference,
  useLoadedUser,
} from "@sempra-event-registration/common";
import classes from "./UserMenu.module.css";

function UserMenu() {
  const user = useLoadedUser();

  if (user == null) {
    return <></>;
  }

  const { email, firstName, photoUrl } = user;
  const displayName = firstName || email;

  async function signOut() {
    await firebaseReference.auth().signOut();
  }

  // show the avatar in place of the down caret if present
  return (
    <Dropdown className={classes.dropdownPadding}>
      <Dropdown.Toggle variant="white">
        <span>{displayName}</span>
        {photoUrl && (
          <Figure>
            <Figure.Image
              width={35}
              height={35}
              alt={displayName}
              src={photoUrl}
            />
          </Figure>
        )}
        <Dropdown.Menu alignRight>
          <Dropdown.Item
            onClick={() =>
              window.open("https://myaccount.microsoft.com/", "_blank")
            }
          >
            Account Settings
          </Dropdown.Item>
          <Dropdown.Item onClick={() => signOut()}>Sign Out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown.Toggle>
    </Dropdown>
  );
}

export default UserMenu;
