import { firebaseReference } from "../../firebase";
import { promiseMutation } from "../../resource";

export const useEventRegistrantSaver = promiseMutation(
  (event, registration) => {
    const updateEventRegistration = firebaseReference
      .functions()
      .httpsCallable("updateEventRegistration");

    return updateEventRegistration({
      eventId: event.id,
      registration,
    })
      .then((response) => response.data)
      .catch((error) => {
        console.error("Failed to save registration", error);
        throw error;
      });
  }
).build();
