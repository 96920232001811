import React from "react";
import { format } from "date-fns-tz";
import { Container } from "react-bootstrap";
import classes from "./EventReleaseWaiverPage.module.css";
import Heading from "../../component/Heading/Heading";

const companyNamesByCompanyCode = {
  sempra: { long: "Sempra Energy", short: "Sempra" },
  sdge: { long: "San Diego Gas & Electric", short: "SDG&E" },
  scg: { long: "Southern California Gas Company", short: "SoCalGas" },
};

function EventReleaseWaiverPage({ event }) {
  const { long, short } = companyNamesByCompanyCode[event.company || "sempra"];
  const timeZone = "America/Los_Angeles";
  const dateNormal = format(event.startsAt, "MMMM dd, yyyy", { timeZone });
  const dateLegal = format(event.startsAt, "do 'day of' MMMM, yyyy", {
    timeZone,
  });

  return (
    <Container className={classes.container}>
      <Heading underlined>
        <h1>Release Waiver</h1>
      </Heading>
      <p className={classes.container}>
        I hereby irrevocably release and grant {long} company ("
        {short}") the right -- without any obligation of further approval or
        compensation in any form -- to utilize my image or picture, name, voice,
        and other information or materials describing or portraying me recorded
        in any form of media by {short} or its representatives on {dateNormal}{" "}
        for internal use only. The utilization rights granted hereby shall
        include all future rights of use by {short} (including by {short}s
        parent and affiliates), and its advertising representatives, at any time
        hereafter. Such uses may include various advertising, training,
        publicity, promotional and other uses regarding {short} and its
        activities, and may appear in any form of media -- including print,
        video, motion picture and sound recordings, still photographs, Internet
        postings and other electronic formats, or otherwise. Materials relating
        to me may be combined with other materials related to such uses,
        including other customers or employees of {short} (or its parent or
        affiliates). All above applications are for internal use only. No
        external use of photograph is allowed.
      </p>

      <p>Executed this {dateLegal} in San Diego, CA</p>
    </Container>
  );
}

export default EventReleaseWaiverPage;
