/**
 * Creates an empty resource or resource pending loading
 */
export function emptyResource() {
  return {
    status: "empty",
  };
}

/**
 * Creates a loading resource
 */
export function loadingResource() {
  return {
    status: "loading",
  };
}

/**
 * Creates a loaded resource with a value
 * @param value The loaded payload
 */
export function loadedResource(value) {
  return {
    status: "loaded",
    value,
  };
}

/**
 * Creates an error resource with an error
 * @param error The error payload
 */
export function errorResource(error) {
  return {
    status: "error",
    error,
  };
}
