import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {
  Loading,
  UserProvider,
  RemoteConfigProvider,
  useRemoteConfigFlags,
  EventRegistrationThemeProvider,
  EventPage,
  Footer,
  ScrollToTop,
} from "@sempra-event-registration/common";
import ProtectedRoute from "./auth/component/ProtectedRoute";
import Header from "./components/Header/Header";
import EventsPage from "./event/page/EventsPage/EventsPage";

function AppProviders({ children }) {
  return (
    <RemoteConfigProvider>
      <UserProvider>
        <EventRegistrationThemeProvider company="sempra">
          {children}
        </EventRegistrationThemeProvider>
      </UserProvider>
    </RemoteConfigProvider>
  );
}

function AppContent() {
  const flags = useRemoteConfigFlags();
  if (flags.status !== "loaded") return <Loading />;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header company="sempra" />
      <main>
        <Switch>
          <ProtectedRoute path="*">
            <Switch>
              <Route path="/events/:eventId">
                <EventPage />
              </Route>
              <Route exact path="/events">
                <EventsPage />
              </Route>
              <Redirect to={`/events${window.location.search}`} />
            </Switch>
          </ProtectedRoute>
        </Switch>
      </main>
      <Footer company="sempra" />
    </BrowserRouter>
  );
}

function App() {
  return (
    <AppProviders>
      <AppContent />
    </AppProviders>
  );
}

export default App;
