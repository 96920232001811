import React from "react";
import { format } from "date-fns";
import classes from "../TimeRange/TimeRange.module.css";

function TimeRange({ from, to, format: formatting = "h:mm aa" }) {
  return (
    <div className={classes.timeRange}>
      <span className={classes.time}>{format(from, formatting)} </span>
      <span className={classes.time}>- {format(to, formatting)}</span>
    </div>
  );
}

export default TimeRange;
