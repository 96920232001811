const identity = (value) => value;

/**
 * Base resource query/mutation builder
 */
export class AbstractResourceBuilder {
  constructor(subscribableProvider) {
    this._subscribableProvider = subscribableProvider;
    this._valueMapper = identity;
    this._errorMapper = identity;
  }

  map(value) {
    return this.mapValue(value);
  }

  mapValue(value) {
    this._valueMapper = value;
    return this;
  }

  mapError(value) {
    this._errorMapper = value;
    return this;
  }

  build() {
    throw new Error("not implemented");
  }
}
