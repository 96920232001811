import React from "react";
import { firebaseReference } from "../firebase";

/** @deprecated Need to make an event-reg and admin specific view */
const AccessDenied = ({ message = "Access Denied" }) => {
  const onSwitchAccountButtonClick = () => firebaseReference.auth().signOut();

  return (
    <div>
      <p>{message}</p>
      <button onClick={onSwitchAccountButtonClick}>Switch Account</button>
    </div>
  );
};

export default AccessDenied;
