import React from "react";
import { Route } from "react-router-dom";
import {
  AccessDenied,
  Error,
  Loading,
  useInternalUser,
} from "@sempra-event-registration/common";

function toArray(valueOrValues) {
  if (valueOrValues == null) {
    return [];
  }
  return Array.isArray(valueOrValues) ? valueOrValues : [valueOrValues];
}

function intersects(a = [], b = []) {
  return a.some((x) => b.some((y) => x === y));
}

function ProtectedRoute(props) {
  const { path, hasAnyPermission, component: Component, ...rest } = props;

  const userResource = useInternalUser();

  if (userResource.status === "empty" || userResource.status === "loading") {
    return <Loading />;
  }

  if (userResource.status === "error") {
    return <Error message={"failed to log in."} />;
  }

  const user = userResource.value;

  const isAuthorized =
    hasAnyPermission == null ||
    intersects(user?.permissions, toArray(hasAnyPermission));

  if (!isAuthorized) {
    return <AccessDenied />;
  }

  return <Route {...rest} />;
}

export default ProtectedRoute;
