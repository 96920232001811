import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useLoadedUser } from "../../../auth";
import { useEvent } from "../../hook/useEvent";
import Loading from "../../component/Loading/Loading";
import NotFound from "../../component/NotFound/NotFound";
import EventHead from "../../component/EventHead/EventHead";
import Event from "../../component/Event/Event";
import EventRegistrationPage from "../EventRegistrationPage/EventRegistrationPage";
import EventRegistrationSuccessPage from "../EventRegistrationSuccessPage/EventRegistrationSuccessPage";
import EventJoinWaitlistSuccessPage from "../EventJoinWaitlistSuccessPage/EventJoinWaitlistSuccessPage";
import EventCancelRegistrationPage from "../EventCancelRegistrationPage/EventCancelRegistrationPage";
import EventCancelRegistrationSuccessPage from "../EventCancelRegistrationSuccessPage/EventCancelRegistrationSuccessPage";
import EventDeclineInvitationPage from "../EventDeclineInvitationPage/EventDeclineInvitationPage";
import EventDeclineInvitationSuccessPage from "../EventDeclineInvitationSuccessPage/EventDeclineInvitationSuccessPage";
import EventReleaseWaiverPage from "../EventReleaseWaiverPage/EventReleaseWaiverPage";
import EventCalendarInvitePage from "../EventCalendarInvitePage/EventCalendarInvitePage";
import EventVirtualMeetingPage from "../EventVirtualMeetingPage/EventVirtualMeetingPage";

function EventPage() {
  const { path, params } = useRouteMatch();
  const user = useLoadedUser();

  const { status, value: event, error } = useEvent(user, params.eventId);
  if (status === "empty" || status === "loading") return <Loading />;
  if (status === "error") {
    console.error("Failed to get event", error);
    return <NotFound />;
  }

  return (
    <div>
      <EventHead {...{ event }} />
      <Switch>
        <Route exact path={path}>
          <Event {...{ event }} />
        </Route>
        <Route exact path={path + "/register"}>
          <EventRegistrationPage {...{ event }} />
        </Route>
        <Route exact path={path + "/registered"}>
          <EventRegistrationSuccessPage initialRegistration {...{ event }} />
        </Route>
        <Route exact path={path + "/registration-updated"}>
          <EventRegistrationSuccessPage {...{ event }} />
        </Route>
        <Route exact path={path + "/waiting"}>
          <EventJoinWaitlistSuccessPage {...{ event }} />
        </Route>
        <Route exact path={path + "/cancel"}>
          <EventCancelRegistrationPage {...{ event }} />
        </Route>
        <Route exact path={path + "/cancelled"}>
          <EventCancelRegistrationSuccessPage {...{ event }} />
        </Route>
        <Route exact path={path + "/decline"}>
          <EventDeclineInvitationPage {...{ event }} />
        </Route>
        <Route exact path={path + "/declined"}>
          <EventDeclineInvitationSuccessPage {...{ event }} />
        </Route>
        <Route exact path={path + "/release-waiver"}>
          <EventReleaseWaiverPage {...{ event }} />
        </Route>
        <Route exact path={path + "/calendar-invite"}>
          <EventCalendarInvitePage {...{ event }} />
        </Route>
        <Route exact path={path + "/virtual-event"}>
          <EventVirtualMeetingPage {...{ event }} />
        </Route>

        <Redirect to={`/events/${event.id}${window.location.search}`} />
      </Switch>
    </div>
  );
}

export default EventPage;
