import React from "react";
import { Controller } from "react-hook-form";
import { isEqual } from "date-fns";
import { timeRangesIntersect } from "../../../../../date";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "../../../Table/Table";
import classes from "./RegistrantWorkshops.module.css";
import Form from "react-bootstrap/Form";
import { groupWorkshopsByStartTime } from "../../../../shared/groupWorkshopsByStartTime";
import TimeRange from "../../../TimeRange/TimeRange";

function RegistrantWorkshops({
  control,
  errors,
  watch,
  setValue,
  getValues,
  workshops,
  ...rest
}) {
  const selectedWorkshops = watch("workshops", getValues().workshops || []);
  const workshopsByStartTime = groupWorkshopsByStartTime(workshops);

  function onWorkshopCheckboxChecked(workshop, value) {
    if (value) {
      if (!selectedWorkshops.some((x) => x.id === workshop.id)) {
        setValue(
          "workshops",
          selectedWorkshops
            .filter(
              (x) =>
                // only works because they are grouped by start time
                !isEqual(x.startTime, workshop.startTime)
            )
            .concat(workshop)
        );
      }
    } else {
      setValue(
        "workshops",
        selectedWorkshops.filter(({ id }) => id !== workshop.id)
      );
    }
  }

  return (
    <div {...rest}>
      <h2 className={classes.heading}>Workshops</h2>

      {/* holds the selected workshop values */}
      <Controller
        control={control}
        name="workshops"
        render={(props) => <input type="hidden" {...props} />}
      />

      <Table striped={false}>
        <Table.Head>
          <tr>
            <th>
              <FontAwesomeIcon icon={faCheck} />
            </th>
            <th>Time</th>
            <th>Workshop Details</th>
          </tr>
        </Table.Head>
        <Table.Body>
          {workshopsByStartTime.map((group, index) => (
            <React.Fragment key={index}>
              {group.values.map((workshop, workshopIndex) => {
                const checked = selectedWorkshops.some(
                  ({ id }) => id === workshop.id
                );

                // Ideally you should be able to select another like a radio button group
                // Question to the users will be how-often are there overlapping workshops
                // TODO capacity checking for workshop room/time to see if workshop is full
                const disabled =
                  !checked &&
                  selectedWorkshops.some(
                    (x) =>
                      // Only disable options in other groups
                      !isEqual(x.startTime, workshop.startTime) &&
                      timeRangesIntersect(
                        x.startTime,
                        x.endTime,
                        workshop.startTime,
                        workshop.endTime
                      )
                  );

                return (
                  <tr
                    key={workshop.id}
                    className={
                      (index & 1) === 1
                        ? classes.evenRowGroup
                        : classes.oddRowGroup
                    }
                  >
                    <td
                      className={`${classes.collapsedCell} ${
                        workshopIndex > 0 ? "orCell" : ""
                      }`}
                    >
                      <Form.Check
                        disabled={disabled}
                        checked={checked}
                        onChange={(e) => {
                          onWorkshopCheckboxChecked(workshop, e.target.checked);
                        }}
                      />
                    </td>
                    <td className={classes.collapsedCell}>
                      <TimeRange
                        {...{
                          from: workshop.startTime,
                          to: workshop.endTime,
                        }}
                      />
                    </td>
                    <td>
                      <p className={classes.workshopName}>
                        <strong>{workshop.name}</strong>
                      </p>
                      <p>{workshop.description}</p>
                    </td>
                  </tr>
                );
              })}
            </React.Fragment>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

export default RegistrantWorkshops;
