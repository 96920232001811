import React from "react";
import BootstrapFormField from "../../../react-hook-form/BootstrapFormField";
import { Controller } from "react-hook-form";

function RegistrantEmployeeContact({ control }) {
  return (
    <Controller
      name="employeeContact"
      control={control}
      render={({ ref, ...props }) => (
        <BootstrapFormField
          {...props}
          type="text"
          label="Employee Contact"
          id="employeeContact"
        />
      )}
    />
  );
}

export default RegistrantEmployeeContact;
