import { isBlank } from "./string";

export function isTenDigitPhoneNumber(value) {
  return value.replace(/\D/g, "").length === 10;
}

export function phone(message = "Please enter a valid ten-digit phone number") {
  return (value) => {
    if (!isBlank(value) && !isTenDigitPhoneNumber(value)) {
      return message;
    }
  };
}
