import React, { useEffect } from "react";
import BootstrapFormField from "../../../../../react-hook-form/BootstrapFormField";
import { Controller } from "react-hook-form";
import classes from "./RegistrantGroups.module.css";

function findFirstAvailableSubgroup(groups = [], selectedGroupId) {
  if (!groups.length) return "";
  const { subgroups = [] } = groups.find((x) => x.id === selectedGroupId) || {};
  const firstOpenSubgroup = subgroups.find(
    (subgroup) => subgroup.registrationCount < subgroup.capacity
  );
  return firstOpenSubgroup?.id || "";
}

function RegistrantGroups({
  className,
  groups,
  control,
  errors,
  watch,
  setValue,
  registration,
}) {
  const registeredGroupId = registration.group?.id || "";
  const registeredSubgroupId = registration.subgroup?.id || "";
  const selectedGroupId = watch("group");

  function setFormValue(formField, value) {
    setValue(formField, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }

  useEffect(() => {
    // If a user hasn't selected a group, pick the first one in the list.
    // If there are no groups, set the group selection to an empty string.
    if (!selectedGroupId) {
      setFormValue("group", groups[0]?.id || "");
    }
    // If a user is already registered for a sub group, preserve their selection.
    // Otherwise, find the first available sub group.
    const newSubGroupId =
      !!registeredSubgroupId && registeredGroupId === selectedGroupId
        ? registeredSubgroupId
        : findFirstAvailableSubgroup(groups, selectedGroupId);

    setFormValue("subgroup", newSubGroupId);
  }, [selectedGroupId]);

  if (!groups.length) return null;

  const { subgroups = [] } =
    groups.find((x) => {
      if (!selectedGroupId) return x?.subgroups;
      return x.id === selectedGroupId;
    }) || {};

  return (
    <div className={className}>
      <h2 className={classes.heading}>Groups</h2>
      <Controller
        name="group"
        control={control}
        rules={{
          required: "Please select a group",
        }}
        render={({ onChange, ref, ...props }) => (
          <BootstrapFormField
            as="select"
            id="group"
            name="group"
            label="Group"
            error={errors.group}
            onChange={(event) => {
              onChange(event.target.value);
            }}
            {...props}
          >
            {groups.map((group) => {
              const currentlyRegistered =
                registeredGroupId === group.id ? " (Registered)" : "";
              return (
                <option key={group.id} value={group.id}>
                  {group.name + currentlyRegistered}
                </option>
              );
            })}
          </BootstrapFormField>
        )}
      />
      {subgroups.length !== 0 && (
        <Controller
          name="subgroup"
          control={control}
          rules={{
            required: "Please select a subgroup",
          }}
          render={({ ref, ...props }) => (
            <BootstrapFormField
              as="select"
              id="subgroup"
              name="subgroup"
              label="Subgroup"
              error={errors.subgroup}
              {...props}
            >
              {subgroups.map(({ id, registrationCount, capacity, name }) => {
                const full = registrationCount >= capacity ? " (Full)" : "";
                const alreadyRegistered =
                  registeredSubgroupId === id ? " (Registered)" : "";
                return (
                  <option
                    key={id}
                    value={id}
                    disabled={
                      !alreadyRegistered && registrationCount >= capacity
                    }
                  >
                    {name + full + alreadyRegistered}
                  </option>
                );
              })}
            </BootstrapFormField>
          )}
        />
      )}
    </div>
  );
}

export default RegistrantGroups;
