import React from "react";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import classes from "./FlagAgreeToTerms.module.css";

const FlagAgreeToTerms = ({ control, eventId, acceptReleaseWaiver }) => {
  return (
    <Controller
      name="acceptReleaseWaiver"
      id="acceptReleaseWaiver"
      control={control}
      defaultValue={acceptReleaseWaiver || false}
      render={({ value, onChange, onBlur, ...rest }) => (
        <Form.Check
          id="acceptReleaseWaiver"
          {...rest}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          type="checkbox"
          checked={value}
          label={
            <div>
              I agree to allow use of photographs of me and recordings of my
              voice.{" "}
              <Link
                className={classes.noWrap}
                target="_blank"
                to={`/events/${eventId}/release-waiver`}
              >
                Read Terms
              </Link>
            </div>
          }
        />
      )}
    />
  );
};

export default FlagAgreeToTerms;
