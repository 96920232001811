import React from "react";
import Card from "react-bootstrap/Card";
import SempraButton from "../SempraButton/SempraButton";
import styles from "./EventCard.module.css";
import EventDateAndTime from "../EventDateAndTime/EventDateAndTime";
import { CroppedImage } from "../../../components";

function EventCard({ event, onEventClick, isFeatured }) {
  const statusClass = event.status === "cancelled" ? styles.cancelled : "";
  return (
    <div>
      <div className={`${styles.eventCardDateAndTime} ${statusClass}`}>
        <EventDateAndTime event={event} />
      </div>
      <div className={styles.eventCardContent} onClick={onEventClick}>
        <div className={styles.imageContainer}>
          <div className={statusClass}>
            <CroppedImage aspectRatio={3} url={event.imageUrl} />
          </div>
          {event.status === "cancelled" && (
            <div className={styles.eventCardCancelledOverlay}>
              <span>CANCELLED</span>
            </div>
          )}
        </div>
        <Card
          className={`${statusClass} ${
            isFeatured ? styles.featuredEventCard : styles.eventCard
          }`}
        >
          <Card.Body className={styles.eventCardBody}>
            <Card.Title className={styles.eventCardTitle}>
              {event.name}
            </Card.Title>
            <Card.Text className={styles.eventCardDescription}>
              {event.summary}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>

      {event.status !== "cancelled" && (
        <SempraButton
          onClick={onEventClick}
          className={`${styles.eventCardButton} ${
            event.isRegistered ? styles.registeredForEventButton : ""
          } ${isFeatured ? styles.buttonOnFeaturedEventCard : ""}`}
        >
          {event.isRegistered
            ? "Registered"
            : event.full
            ? "Join Watilist"
            : "Learn More"}
        </SempraButton>
      )}
    </div>
  );
}

export default EventCard;
