import React from "react";
import Heading from "../Heading/Heading";
import { formatPhoneNumber } from "../../shared/formatting";
import { isBlank } from "../../../form-validation";

function EventContactInformation({ contact = {}, ...rest }) {
  return (
    <div {...rest}>
      <Heading underlined>
        <h2>Questions?</h2>
      </Heading>
      <p>
        Please reach out to <strong>{contact.name}</strong>
      </p>
      <div>
        {!isBlank(contact.email) && (
          <div>
            Email:
            <a href={`mailto:${contact.email}`}>{contact.email}</a>
          </div>
        )}
        {!isBlank(String(contact.phone)) && (
          <div>
            Phone:
            <a href={`tel:${contact.phone}`}>
              {formatPhoneNumber(contact.phone)}
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default EventContactInformation;
