import { resourceQuery } from "../resourceQuery";
import { resourceMutation } from "../resourceMutation";

/**
 * @param promiseProvider Function returning a promise to listen to
 * @return {ResourceQueryBuilder} A resource query builder
 */
export function promiseQuery(promiseProvider) {
  return resourceQuery((...args) => (onNext, onError) =>
    promiseProvider(...args)
      .then((value) => {
        onNext(value);
        return value;
      })
      .catch(onError)
  );
}

/**
 * @param promiseProvider Function returning a promise to listen to
 * @return {ResourceMutationBuilder} A resource mutation builder
 */
export function promiseMutation(promiseProvider) {
  return resourceMutation((...args) => (onNext, onError) =>
    promiseProvider(...args)
      .then((value) => {
        onNext(value);
        return value;
      })
      .catch(onError)
  );
}
