export const logoImagesByCompanyCode = {
  sempra: {
    src: "/sempra-logo-color.png",
    width: "198px",
    height: "auto",
    alt: "Sempra",
  },
  sdge: {
    src: "/sdge-logo-color.png",
    width: "125px",
    height: "auto",
    alt: "SDG&E",
  },
  scg: {
    src: "/scg-logo-color.png",
    width: "147px",
    height: "auto",
    alt: "SoCalGas",
  },
};
