import { resourceQuery } from "../resourceQuery";

function firestoreQuery(referenceProvider) {
  return resourceQuery((...args) => (onNext, onError) =>
    referenceProvider(...args).onSnapshot(onNext, onError)
  );
}

/**
 * @param collectionReferenceProvider Function returning the firestore collection reference to listen to
 * @return {ResourceQueryBuilder} A resource query builder
 */
export function firestoreCollectionQuery(collectionReferenceProvider) {
  return firestoreQuery(collectionReferenceProvider).defaultValue([]);
}

/**
 * @param documentReferenceProvider Function returning the firestore document reference to listen to
 * @return {ResourceQueryBuilder} A resource query builder
 */
export function firestoreDocumentQuery(documentReferenceProvider) {
  return resourceQuery((...args) => (onNext, onError) =>
    documentReferenceProvider(...args).onSnapshot((snapshot) => {
      if (!snapshot.exists) {
        onError(new Error("Not found"));
      } else {
        onNext(snapshot);
      }
    }, onError)
  );
}
