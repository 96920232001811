import React from "react";
import BootstrapFormField from "../../../react-hook-form/BootstrapFormField";
import { Controller } from "react-hook-form";
import { requiredNotBlank } from "../../../form-validation";

function RegistrantJobTitle({ control, errors, ...rest }) {
  return (
    <Controller
      name="title"
      control={control}
      rules={{
        validate: {
          required: requiredNotBlank("Required"),
        },
      }}
      render={({ ref, ...props }) => (
        <BootstrapFormField
          type="text"
          label="Job Title"
          id="title"
          error={errors.title}
          {...props}
          {...rest}
        />
      )}
    />
  );
}

export default RegistrantJobTitle;
