/**
 * Removes characters unsafe to use in filenames on filesystems
 * @param value The name to sanitize
 * @return {*} The filesystem-safe name
 */
function filesystemSafeName(value) {
  return value.replace(/[/\\?%*:|"<>]/g, "");
}

/**
 * Initiates a download of the given text as the given filename
 * @param filename The name to download the content as
 * @param text The file content as text
 * @param mimeType The file type and character encoding of the file
 */
export function download(
  filename,
  text,
  mimeType = "text/plain;charset=utf-8"
) {
  const element = document.createElement("a");
  element.style.display = "none";
  element.setAttribute("href", `data:${mimeType},` + encodeURIComponent(text));
  element.setAttribute("download", filesystemSafeName(filename));

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
