export const numberComparator = (a, b) => a - b;
export const stringComparator = (a, b) => a.localeCompare(b);
export const dateComparator = (a, b) => a.getTime() - b.getTime();

/**
 * Creates a new comparator that will reverse the ordering of the given comparator
 * @param comparator The comparator to reverse
 * @return {function(*=, *=): *}
 */
export function reverse(comparator) {
  return (a, b) => comparator(b, a);
}

/**
 * Creates a new comparator that will treat null values as belonging before non-null
 * @param comparator The comparator to wrap
 * @return {function(*=, *=): *}
 */
export function nullsFirst(comparator = () => 0) {
  return (a, b) => {
    if (a == null) {
      return -1;
    }
    if (b == null) {
      return 1;
    }
    return comparator(a, b);
  };
}

/**
 * Creates a new comparator that will treat null values as belonging after non-null
 * @param comparator The comparator to wrap
 * @return {function(*=, *=): *}
 */
export function nullsLast(comparator = () => 0) {
  return (a, b) => {
    if (a == null) {
      return 1;
    }
    if (b == null) {
      return -1;
    }
    return comparator(a, b);
  };
}

/**
 * Creates a comparator that sorts based on where in the provided values the value under comparison falls
 * @param orderedValues The ordered set of values
 * @return {function(*=, *=): *}
 */
export function ordering(orderedValues) {
  return (a, b) => {
    let indexA = orderedValues.indexOf(a);
    let indexB = orderedValues.indexOf(b);

    // Change -1 to null so it does not pass as a valid index in the comparison logic
    indexA = indexA === -1 ? null : indexA;
    indexB = indexB === -1 ? null : indexB;

    if (indexA < indexB) {
      return -1;
    }
    if (indexA > indexB) {
      return 1;
    }
    return 0;
  };
}

/**
 * Creates a new comparator that sorts the compared values based on the result of the given accessor on the compared value
 * @param comparator The comparator to wrap
 * @param accessor The accessor called on the compared value (think of a field getter: (x) => x.name)
 * @return {function(*=, *=): *}
 */
export function comparing(comparator, accessor) {
  return (a, b) => comparator(accessor(a), accessor(b));
}

/**
 * Composes the provided comparators into a new comparator with a "then" relationship.
 * e.g. compare on comparator 1, then 2, then 3, etc...
 * @param comparators The comparators to use
 * @return {function(...[*]=)} The composite comparator
 */
export function composeComparators(...comparators) {
  return (a, b) => {
    for (let i = 0; i < comparators.length; i++) {
      const comparison = comparators[i](a, b);
      if (comparison !== 0) {
        return comparison;
      }
    }
    return 0;
  };
}
