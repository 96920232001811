import { useEffect, useState } from "react";
import { useUser } from "./UserContext";
import { firebaseReference } from "../firebase";
import { errorResource, loadedResource, loadingResource } from "../resource";

function createUser(firebaseAuthUser, claims, userDocumentData) {
  const { email: id } = firebaseAuthUser;
  const { permissions = [] } = claims;
  return {
    ...userDocumentData,
    type: "internal",
    id,
    permissions,
  };
}

export function userHook({ onSignedOut, onRedirectResult }) {
  return () => {
    const [user, setUser] = useUser();
    const [redirectResult, setRedirectResult] = useState();
    const [firebaseUser, setFirebaseUser] = useState();
    const [lastSync, setLastSync] = useState({});
    const onError = (error) => setUser(errorResource(error));

    useEffect(() => {
      if (onRedirectResult != null) {
        firebaseReference
          .auth()
          .getRedirectResult()
          .then((result) => {
            setRedirectResult(result);
            onRedirectResult(result);
          })
          .catch(onError);
      }
    }, []);

    useEffect(
      () => {
        if (onRedirectResult != null && redirectResult == null) {
          return;
        }
        setUser(loadingResource());
        return firebaseReference
          .auth()
          .onAuthStateChanged(async (firebaseUser) => {
            if (firebaseUser == null) {
              onSignedOut();
            } else {
              setFirebaseUser(firebaseUser);
            }
          }, onError);
      },
      onRedirectResult != null ? [redirectResult] : []
    );

    useEffect(() => {
      if (firebaseUser == null) {
        return;
      }
      return firebaseReference
        .firestore()
        .collection("internal_users")
        .doc(firebaseUser.email)
        .onSnapshot(async (snapshot) => {
          const {
            _lastSync: serverLastSync,
            ...userDocumentData
          } = snapshot.exists ? snapshot.data() : {};

          // The first login will take a minute
          // because the cloud function is responsible for setting this field
          if (serverLastSync != null) {
            // If we detect the client is out of sync with the server we need to wait until we get the
            // latest custom claims before we create our user
            const refreshToken = !serverLastSync.isEqual(lastSync);
            const tokenResult = await firebaseUser.getIdTokenResult(
              refreshToken
            );
            const user = createUser(
              firebaseUser,
              tokenResult.claims,
              userDocumentData
            );

            setLastSync(serverLastSync);
            setUser(loadedResource(user));
          }
        }, onError);
    }, [firebaseUser]);

    return user;
  };
}
