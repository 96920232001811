import React from "react";
import classes from "./EventRegistrationThemeProvider.module.css";

const companyClass = {
  sdge: {
    classes: classes.eventRegistrationSdge,
  },
  sempra: {
    classes: classes.eventRegistrationSempra,
  },
};

function EventRegistrationThemeProvider({
  company = "sempra",
  children,
  ...rest
}) {
  const { classes } = companyClass[company] || companyClass.sempra;
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
}

export default EventRegistrationThemeProvider;
