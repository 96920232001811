import { useEffect, useState } from "react";
import {
  emptyResource,
  errorResource,
  loadedResource,
  loadingResource,
} from "../core/resourceFactories";
import { AbstractResourceBuilder } from "./AbstractResourceBuilder";

const alwaysTrue = () => true;

class ResourceQueryBuilder extends AbstractResourceBuilder {
  constructor(subscribableProvider) {
    super(subscribableProvider);
    this._condition = alwaysTrue;
  }

  initialValue(value) {
    this._initialValue = value;
    return this;
  }

  defaultValue(value) {
    this._defaultValue = value;
    return this;
  }

  condition(value) {
    this._condition = value;
    return this;
  }

  build() {
    const {
      _subscribableProvider,
      _valueMapper,
      _errorMapper,
      _initialValue,
      _defaultValue,
      _condition,
    } = this;

    /**
     * Handle mapping an error condition to the appropriate resource (either an {@link errorResource} or
     * {@link loadedResource}).
     * <p>
     *   In some cases it is okay for the {@link resourceQuery} to return an error.  For example, a query against a
     *   Firestore collection for a single document will return an error if the document does not exist (or if the query
     *   returns no records).  In some cases, this might be acceptable behavior.  The
     *   {@link AbstractResourceBuilder#mapError} allows us to define an error mapper.  However, when the error occurs, we
     *   want to be able to return a {@link loadedResource} instead of an {@link errorResource} (because while there may
     *   have technically been an error condition, our application does not want to treat it as such).
     * </p>
     *
     * @param error {any} The error encountered during the {@link resourceQuery}'s execution
     * @param mapper {any} The mapper that handles mapping the error to the resource value field
     * @returns {{error: *, status: string}|{value: *, status: string}} If the error is of type {@link Error}, an {@link errorResource} is returned with the error set in its error field; otherwise a {@link loadedResource} with the error mapper's results in its value field
     */
    function handleErrorMapping(error, mapper) {
      const mappedError = mapper(error);
      return mappedError instanceof Error
        ? errorResource(mappedError)
        : loadedResource(mappedError);
    }

    return (...args) => {
      const [state, setState] = useState(
        _initialValue != null ? loadedResource(_initialValue) : emptyResource()
      );

      useEffect(() => {
        if (!_condition(...args)) {
          if (_defaultValue != null) {
            setState(
              loadedResource(
                typeof _defaultValue === "function"
                  ? _defaultValue(...args)
                  : _defaultValue
              )
            );
          }
          return;
        }
        setState(loadingResource());
        const subscribable = _subscribableProvider(...args);
        const unsubscribe = subscribable(
          (value) => {
            try {
              setState(loadedResource(_valueMapper(value)));
            } catch (error) {
              // this is for handling any errors during the _valueMapper's execution
              setState(handleErrorMapping(error, _errorMapper));
            }
          },
          (error) => {
            // this is for handling other error conditions
            setState(handleErrorMapping(error, _errorMapper));
          }
        );
        if (typeof unsubscribe === "function") {
          return unsubscribe;
        }
      }, [...args]);
      return state;
    };
  }
}

/**
 * Creates a resource query builder
 * @param subscribableProvider Function providing a subscribable function that performs query logic
 * @return {ResourceQueryBuilder}
 */
export function resourceQuery(subscribableProvider) {
  return new ResourceQueryBuilder(subscribableProvider);
}
