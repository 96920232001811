import { timeOf } from "../../date";
import { isEqual } from "date-fns";
import { workshopComparator } from "./workshopComparator";
import { comparing, dateComparator } from "../../sorting";

export function groupWorkshopsByStartTime(workshops) {
  return workshops
    .reduce((groups, workshop) => {
      const startTime = timeOf(workshop.startTime);
      const group = groups.find((x) => isEqual(timeOf(x.startTime), startTime));
      if (group != null) {
        group.values.push(workshop);
        group.values.sort(workshopComparator);
      } else {
        groups.push({
          startTime,
          values: [workshop],
        });
      }
      return groups;
    }, [])
    .sort(comparing(dateComparator, (x) => x.startTime));
}
