import React, { useEffect, useContext, useState } from "react";

/**
 * Creates a state-backed context factory
 * @param initialState The initial state of the context
 */
export function stateBackedContext(initialState, initFunction = () => {}) {
  const Context = React.createContext([initialState, () => {}]);

  function Provider({ children }) {
    const state = useState(initialState);

    useEffect(() => initFunction(state), []);

    return <Context.Provider value={state}>{children}</Context.Provider>;
  }

  function use() {
    return useContext(Context);
  }

  return {
    Context,
    Provider,
    use,
  };
}
