import React from "react";
import Container from "react-bootstrap/Container";
import { useHistory } from "react-router-dom";
import classes from "./EventJoinWaitlistSuccessPage.module.css";
import SempraButton from "../../component/SempraButton/SempraButton";

function EventJoinWaitlistSuccessPage({ event }) {
  const history = useHistory();
  function onBackButtonClick() {
    history.push(`/${window.location.search}`);
  }

  return (
    <div>
      <Container>
        <h1 className={classes.heading}>Thank you for joining the waitlist!</h1>
        <p>
          We will notify you as soon as an opening for{" "}
          <strong>{event.name}</strong> becomes available.
        </p>
        <SempraButton
          className={classes.actionButton}
          type="button"
          outlined
          onClick={onBackButtonClick}
        >
          Back to Events
        </SempraButton>
      </Container>
    </div>
  );
}

export default EventJoinWaitlistSuccessPage;
