import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import RegistrantFirstName from "./RegistrantFirstName";
import RegistrantLastName from "./RegistrantLastName";
import RegistrantEmail from "./RegistrantEmail";
import RegistrantCompanyName from "./RegistrantCompanyName";
import RegistrantJobTitle from "./RegistrantJobTitle";
import RegistrantEmployeeContact from "./RegistrantEmployeeContact";
import RegistrantMeals from "./component/RegistrantMeals/RegistrantMeals";
import RegistrantPhone from "./RegistrantPhone";
import FlagFirstEvent from "./FlagFirstEvent";
import FlagUnion from "./FlagUnion";
import FlagAgreeToTerms from "./FlagAgreeToTerms";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import RegistrantGroups from "./component/RegistrantGroups/RegistrantGroups";
import RegistrantWorkshops from "./component/RegistrantWorkshops/RegistrantWorkshops";
import SempraButton from "../SempraButton/SempraButton";
import classes from "./EventRegistrationForm.module.css";

function EventRegistrationForm({
  event,
  defaultValues,
  disabledFields = {},
  onSubmit = () => {},
  submitting = false,
}) {
  const history = useHistory();
  const { control, errors, watch, setValue, getValues, trigger } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
  });
  const fieldProps = { control, errors };
  const {
    meals = [],
    groups = [],
    workshops = [],
    registration,
    company,
  } = event;

  const eligibleToJoinWaitlist =
    event.full && registration.status !== "invited-from-waitlist";

  function onCancelButtonClick() {
    history.push(`/events/${event.id}${window.location.search}`);
  }

  async function onSubmitButtonClick(event) {
    const valid = await trigger();
    console.log("valid", valid);
    if (valid) {
      onSubmit(getValues(), event);
    } else {
      setTimeout(() => {
        const firstInvalidField = document.querySelector(".is-invalid");
        console.log("firstInvalidField", firstInvalidField);
        firstInvalidField.scrollIntoView({ behavior: "smooth" });
      });
    }
  }

  return (
    <Form className={classes.form}>
      <div className={classes.narrowColumn}>
        <Form.Row>
          <Col sm={6}>
            <RegistrantFirstName
              {...fieldProps}
              disabled={disabledFields.firstName}
            />
          </Col>
          <Col sm={6}>
            <RegistrantLastName
              {...fieldProps}
              disabled={disabledFields.lastName}
            />
          </Col>
        </Form.Row>
        <RegistrantEmail {...fieldProps} disabled={disabledFields.email} />
        <RegistrantPhone {...fieldProps} disabled={disabledFields.phone} />
        <RegistrantCompanyName
          {...fieldProps}
          disabled={disabledFields.companyName}
        />
        <RegistrantJobTitle {...fieldProps} disabled={disabledFields.title} />
        {event.askForCompanyContact && (
          <RegistrantEmployeeContact {...fieldProps} />
        )}
        {meals.length > 0 && (
          <RegistrantMeals {...{ ...fieldProps, meals, setValue, watch }} />
        )}
        {!eligibleToJoinWaitlist && (
          <RegistrantGroups
            className={classes.section}
            {...{
              ...fieldProps,
              groups,
              watch,
              setValue,
              registration,
            }}
          />
        )}
      </div>

      {workshops.length > 0 && (
        <RegistrantWorkshops
          className={classes.section}
          {...{ ...fieldProps, workshops, watch, setValue, getValues }}
        />
      )}

      <div className={classes.flags}>
        {event.askIfFirstEvent && (
          <FlagFirstEvent
            {...fieldProps}
            company={company}
            askIfFirstEvent={registration.firstEvent}
          />
        )}
        {event.askIfRepresentedByUnion && (
          <FlagUnion
            {...fieldProps}
            representedByUnion={registration.representedByUnion}
          />
        )}
        {event.askForReleaseWaiver && (
          <FlagAgreeToTerms
            eventId={event.id}
            {...fieldProps}
            acceptReleaseWaiver={registration.acceptReleaseWaiver}
          />
        )}
      </div>

      <div className={classes.controls}>
        <SempraButton
          type="button"
          loading={submitting}
          onClick={onSubmitButtonClick}
        >
          {["registered", "waiting"].includes(registration.status)
            ? submitting
              ? "Changing Registration..."
              : "Change Registration"
            : eligibleToJoinWaitlist
            ? submitting
              ? "Joining Waitlist..."
              : "Join Waitlist"
            : submitting
            ? "Registering..."
            : "Register"}
        </SempraButton>
        <SempraButton type="button" outlined onClick={onCancelButtonClick}>
          Back
        </SempraButton>
      </div>
    </Form>
  );
}

export default EventRegistrationForm;
