import React from "react";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
const FlagUnion = ({ control, representedByUnion }) => {
  return (
    <Controller
      name="representedByUnion"
      id="representedByUnion"
      control={control}
      defaultValue={representedByUnion || false}
      render={({ value, onChange, onBlur, ...rest }) => (
        <Form.Check
          id="representedByUnion"
          {...rest}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          type="checkbox"
          label="I am represented by a Union"
          checked={value}
        />
      )}
    />
  );
};

export default FlagUnion;
