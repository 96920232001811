import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EventLocation from "../EventLocation/EventLocation";
import EventRegister from "../EventRegister/EventRegister";
import EventResources from "../EventResources/EventResources";
import classes from "./EventActionsGrid.module.css";

function EventActionsGrid({ event }) {
  return (
    <Container className={classes.parentContainer} fluid>
      <Container>
        <Row className={classes.informationRowLayout}>
          <Col md={12} lg={4} className={classes.informationColLayout}>
            <EventLocation event={event} />
          </Col>
          <Col md={12} lg={4} className={classes.informationColLayout}>
            <EventRegister event={event} />
          </Col>
          <Col md={12} lg={4} className={classes.informationColLayout}>
            <EventResources event={event} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default EventActionsGrid;
