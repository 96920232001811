import React from "react";
import BootstrapFormField from "../../../../../react-hook-form/BootstrapFormField";
import { Controller } from "react-hook-form";

function RegistrantDietaryRestrictions({ control, ...rest }) {
  return (
    <div {...rest}>
      <Controller
        name="dietaryRestrictions"
        control={control}
        render={({ ref, ...props }) => (
          <BootstrapFormField
            {...props}
            type="text"
            placeholder="none"
            label="Dietary Restrictions"
            id="dietaryRestrictions"
          />
        )}
      />
    </div>
  );
}

export default RegistrantDietaryRestrictions;
