import { firebaseReference } from "../../firebase";
import { firestoreCollectionQuery } from "../../resource";

function documentToRegistrant(document) {
  const { id, sourceRef, createdAt, updatedAt, ...rest } = document.data();
  return {
    ...rest,
    id: document.id,
    eventId: document.ref.parent.parent.id,
    createdAt: createdAt?.toDate(),
    updatedAt: updatedAt?.toDate(),
  };
}

/**
 * Get all event registration records for a user.
 *
 * @param {string} userId the user's identifier (usually an email address, but not necessarily the same as user.email)
 * @return {Array} An array of objects representing all of the events this user is registered for
 */
export const useEventRegistrants = firestoreCollectionQuery((user) =>
  firebaseReference
    .firestore()
    .collectionGroup("readonly_registrations")
    .where("internalUserId", "==", user.id)
)
  .map((snapshot) => snapshot.docs.map(documentToRegistrant))
  .mapError((error) => {
    console.error("Failed to get registrants", error);
    return error;
  })
  .initialValue([])
  .build();
