import React from "react";
import BootstrapFormField from "../../../react-hook-form/BootstrapFormField";
import { emailPattern, requiredNotBlank } from "../../../form-validation";
import { Controller } from "react-hook-form";

function RegistrantEmail({ control, errors, ...rest }) {
  return (
    <Controller
      name="email"
      control={control}
      rules={{
        validate: requiredNotBlank("Required"),
        pattern: {
          value: emailPattern,
          message: "Please enter a valid email",
        },
      }}
      render={({ ref, ...props }) => (
        <BootstrapFormField
          error={errors.email}
          type="text"
          label="Email"
          id="email"
          {...props}
          {...rest}
        />
      )}
    />
  );
}

export default RegistrantEmail;
