import React from "react";
import SempraButton from "../SempraButton/SempraButton";
import Heading from "../Heading/Heading";
import classes from "./EventAddToCalendar.module.css";
import { createICS } from "../../../event";

function EventAddToCalendar({ event, registration }) {
  function onDownloadButtonClick(e) {
    e.preventDefault();
    createICS(event, registration);
  }

  return (
    <div>
      <Heading highlighted>
        <h2>Add to Calendar</h2>
      </Heading>
      <SempraButton onClick={onDownloadButtonClick}>Download</SempraButton>
      <p className={classes.description}>
        Add this event to your mobile phone or desktop calendar
      </p>
    </div>
  );
}

export default EventAddToCalendar;
