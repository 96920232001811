import React from "react";
import BootstrapFormField from "../../../../../react-hook-form/BootstrapFormField";
import RegistrantDietaryRestrictions from "./RegistrantDietaryRestrictions";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Controller, useFieldArray } from "react-hook-form";
import styles from "./RegistrantMeals.module.css";

function RegistrantMeals({ meals = [], control, watch, setValue }) {
  const { fields } = useFieldArray({
    control,
    name: "meals",
  });
  return (
    <React.Fragment>
      <h2 className={styles.heading}>Meals</h2>
      <p>Please choose the meals you plan on attending.</p>

      {fields.map((field, index) => {
        const checked = watch(`meals[${index}].selected`);
        return (
          <Row key={field.id} className={styles.mealRow}>
            <Col sm={3}>
              {/* We need a controlled hidden input field here so we can keep track of the meal's unique ID.  Since the
              field is controlled, the useForm() (which is up higher in the component tree, specifically in the
              EventRegistrationForm component) will track this as a form value just like anything else.  We can use this
              id elsewhere in the app to identify which meal is selected. */}
              <Controller
                control={control}
                name={`meals[${index}].id`}
                defaultValue={field.id}
                render={({ ref, ...props }) => (
                  <input type="hidden" {...props} />
                )}
              />
              <Controller
                name={`meals[${index}].selected`}
                control={control}
                defaultValue={field.selected || false}
                render={({ value, onChange, ref, ...props }) => (
                  <Form.Check
                    id={`meal${index}`}
                    type="checkbox"
                    label={meals[index].name}
                    checked={value}
                    onChange={(e) => {
                      const { options = [] } = meals[index];
                      if (options.length > 0) {
                        setValue(`meals[${index}].option`, options[0].id);
                      }
                      onChange(e.target.checked);
                    }}
                    {...props}
                  />
                )}
              />
            </Col>
            <Col sm={9}>
              <Controller
                name={`meals[${index}].option`}
                control={control}
                defaultValue={field.option || null}
                render={({ ref, ...props }) => {
                  const { options = [] } = meals[index];
                  return (
                    <BootstrapFormField
                      className={
                        !checked || options.length === 0 ? styles.hidden : ""
                      }
                      as="select"
                      {...props}
                    >
                      {options.map((option, index) => (
                        <option key={index} value={option.id}>{`${
                          option.name
                        } ${
                          option.dietaryType
                            ? "(" + option.dietaryType + ")"
                            : ""
                        }`}</option>
                      ))}
                    </BootstrapFormField>
                  );
                }}
              />
            </Col>
          </Row>
        );
      })}
      <RegistrantDietaryRestrictions
        className={styles.dietaryRestrictions}
        control={control}
      />
    </React.Fragment>
  );
}

export default RegistrantMeals;
