import { resourceQuery } from "../../resource";
import { firebaseReference } from "../../firebase";

function documentToRegistrant(document) {
  const { id, sourceRef, createdAt, updatedAt, ...rest } = document.data();
  return {
    ...rest,
    id: document.id,
    eventId: document.ref.parent.parent.id,
    createdAt: createdAt?.toDate(),
    updatedAt: updatedAt?.toDate(),
  };
}

export const useEventRegistrant = resourceQuery(
  (user, eventId, registrantId) => (onNext, onError) => {
    // If you are an external user who deep linked in with a registration ID use it
    if (registrantId != null) {
      return firebaseReference
        .firestore()
        .doc(
          `readonly_events/${eventId}/readonly_registrations/${registrantId}`
        )
        .onSnapshot((snapshot) => {
          if (!snapshot.exists) {
            onError(new Error("Not found"));
          } else {
            onNext(snapshot);
          }
        }, onError);
    }

    // If you are an authenticated internal user use your ID
    if (user.id != null) {
      return firebaseReference
        .firestore()
        .collection(`readonly_events/${eventId}/readonly_registrations`)
        .where("internalUserId", "==", user.id)
        .onSnapshot((snapshot) => {
          if (snapshot.docs.length === 0) {
            onError(new Error("Not found"));
          } else {
            onNext(snapshot.docs[0]);
          }
        }, onError);
    }

    // If you are an external user with no registration deep link
    onError(new Error("Not found"));
    return () => {};
  }
)
  .map(documentToRegistrant)
  .mapError((error) => {
    // If a registrant document cannot be found in the collection, that's okay.  No document in the registrants
    // collection means the user is not registered for this event; return an empty object.  Otherwise, return the
    // exception that occurred.  There doesn't seem to be a better way to detect the type of error this is; looking at
    // the message seems to be the best/most reliable approach.
    if (error.message.trim().toLowerCase() === "not found") {
      return;
    }

    console.error("Failed to get registrant", error);

    return error;
  })
  .defaultValue({})
  .build();
