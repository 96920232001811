import React, { useState } from "react";
import SempraButton from "../SempraButton/SempraButton";
import Toast from "react-bootstrap/Toast";
import classes from "../ShareEventButton/ShareEventButton.module.css";
import { copyToClipboard } from "../../../dom";

export function eventIdToEventUrl(eventId) {
  const { protocol, host } = window.location;
  return `${protocol}//${host}/events/${eventId}`;
}

function ShareEventButton({ eventId }) {
  const [
    showCopySuccessNotification,
    setShowCopySuccessNotification,
  ] = useState(false);

  async function onShareEventButtonClick() {
    await copyToClipboard(eventIdToEventUrl(eventId));
    setShowCopySuccessNotification(true);
  }

  return (
    <div className={classes.toastContainer}>
      <SempraButton
        text="Share Event"
        onClick={onShareEventButtonClick}
        outlined
      />
      <Toast
        className={classes.toast}
        show={showCopySuccessNotification}
        autohide
        delay={2000}
        onClose={() => {
          setShowCopySuccessNotification(false);
        }}
      >
        <Toast.Body>URL copied to clipboard!</Toast.Body>
      </Toast>
    </div>
  );
}

export default ShareEventButton;
