import React from "react";
import SempraButton from "../SempraButton/SempraButton";
import classes from "./ViewMoreButton.module.css";

function ViewMoreButton({ children, ...props }) {
  return (
    <div className={classes.container}>
      <div className={classes.divider} />
      <div className={classes.button}>
        <SempraButton outlined secondary {...props}>
          {children || "View More"}
        </SempraButton>
      </div>
    </div>
  );
}

export default ViewMoreButton;
