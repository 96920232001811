import React from "react";
import {
  format,
  getYear,
  getMonth,
  getDate,
  getHours,
  getMinutes,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import * as ics from "ics";
import { download } from "../dom";

function eventNameToFileName(value) {
  return value.trim().replace(/[\s/\\?%*:|"<>]+/g, "_") + ".ics";
}

const getWorkshops = (workshops) => {
  if (workshops == null || workshops.length === 0) {
    return "None Selected";
  }

  return workshops
    .map(
      (workshop) =>
        `${format(workshop.startTime.toDate(), "h:mm aa")} - ${format(
          workshop.endTime.toDate(),
          "h:mm aa"
        )}
          ${workshop.name}
          ${workshop.description}
          Location > ${workshop.room.name}
      `
    )
    .join("\n");
};

const getMeals = (meals) => {
  if (meals == null || meals.length === 0) {
    return "None Selected";
  }

  return meals
    .map((meal) =>
      meal.option ? `${meal.name}: ${meal.option.name}` : `${meal.name}`
    )
    .join("\n");
};

const getLocation = (location, isVirtualEvent) => {
  if (isVirtualEvent) return "Microsoft Teams";
  return `${location.name} ${location.address.line1} ${
    location.address.rooms || ""
  } ${location.address.line2 || ""} ${location.address.city}, ${
    location.address.state
  } ${location.address.zipCode}`;
};

const getEventDetailUrl = (eventId, registrantId) => {
  return `${window.location.origin}/events/${eventId}?registrationId=${registrantId}`;
};

const getVirtualEventUrl = (eventId) => {
  return `Link to Event: ${window.location.origin}/events/${eventId}/virtual-event`;
};

const getInternalEventDescription = (event, registrant) => {
  const {
    location,
    name,
    id: eventId,
    isVirtualEvent,
    virtualEventLink,
  } = event;
  const {
    workshops,
    meals,
    group,
    subgroup,
    id: registrantId,
    status,
  } = registrant;

  let locationDetails;
  if (isVirtualEvent) locationDetails = virtualEventLink;
  else
    locationDetails = `${location.name}
  ${location.address.line1} ${location.address.rooms || ""}
  ${location.address.line2 || ""}
  ${location.address.city}, ${location.address.state} ${
      location.address.zipCode
    }
  ${
    location.address.additionalDirections
      ? "Additional Directions:\n" + location.address.additionalDirections
      : "No Additional Directions"
  }`;

  if (isVirtualEvent && status === "registered") {
    return `${getVirtualEventUrl(eventId)}
    
    We look forward to seeing you at:

    ${name}
    -------------------
    Your Workshops:

      ${getWorkshops(workshops)}

    Your Selections:

      ${getMeals(meals)}
      Dietary Restrictions: ${registrant.dietaryRestrictions}
      Group: ${(group != null && group.name) || "none selected"}
      Subgroup: ${(subgroup != null && subgroup.name) || "none selected"}

    Need to make a change?
    Visit ${getEventDetailUrl(eventId, registrantId)}`;
  } else {
    return `We look forward to seeing you at:

    ${name}
    -------------------
    Your Workshops:

      ${getWorkshops(workshops)}

    Your Selections:

      ${getMeals(meals)}
      Dietary Restrictions: ${registrant.dietaryRestrictions}
      Group: ${(group != null && group.name) || "none selected"}
      Subgroup: ${(subgroup != null && subgroup.name) || "none selected"}

    Location Details:

    ${locationDetails}
    Need to make a change?
    Visit ${getEventDetailUrl(eventId, registrantId)}`;
  }
};

const getExternalEventDescription = (event, registrant) => {
  const {
    location,
    name,
    id: eventId,
    isVirtualEvent,
    virtualEventLink,
  } = event;
  const { meals = [], group, subgroup, id: registrantId, status } = registrant;

  let locationDetails;
  if (isVirtualEvent) locationDetails = virtualEventLink;
  else
    locationDetails = `${location.name}
  ${location.address.line1} ${location.address.rooms || ""}
  ${location.address.line2 || ""}
  ${location.address.city}, ${location.address.state} ${
      location.address.zipCode
    }
  ${
    location.address.additionalDirections
      ? "Additional Directions:\n" + location.address.additionalDirections
      : "No Additional Directions"
  }`;

  if (isVirtualEvent && status === "registered") {
    return `${getVirtualEventUrl(eventId)}
    
    We look forward to seeing you at:

    ${name}
   
    Your Selections:

      ${getMeals(meals)}
      Dietary Restrictions: ${registrant.dietaryRestrictions}
      Group: ${(group != null && group.name) || "none selected"}
      Subgroup: ${(subgroup != null && subgroup.name) || "none selected"}

    Need to make a change?
    Visit ${getEventDetailUrl(eventId, registrantId)}`;
  } else {
    return `We look forward to seeing you at:

    ${name}

    Your Selections:

      ${getMeals(meals)}
      Dietary Restrictions: ${registrant.dietaryRestrictions}
      Group: ${(group != null && group.name) || "none selected"}
      Subgroup: ${(subgroup != null && subgroup.name) || "none selected"}

    Location Details:

    ${locationDetails}

    Need to make a change?
    Visit ${getEventDetailUrl(eventId, registrantId)}`;
  }
};

export const createICS = (event, registrant) => {
  const {
    startsAt,
    endsAt,
    name,
    id,
    contact,
    location,
    type,
    isVirtualEvent,
  } = event;
  const calendarEvent = {
    start: [
      getYear(startsAt),
      getMonth(startsAt) + 1,
      getDate(startsAt),
      getHours(startsAt),
      getMinutes(startsAt),
    ],
    duration: {
      hours: differenceInHours(endsAt, startsAt),
      minutes: differenceInMinutes(endsAt, startsAt) % 60,
    },
    title: name,
    description:
      type === "internal "
        ? getInternalEventDescription(event, registrant)
        : getExternalEventDescription(event, registrant),
    location: getLocation(location, isVirtualEvent),
    url: getEventDetailUrl(id),
    status: "CONFIRMED",
    busyStatus: "BUSY",
    organizer: {
      name: contact.name,
      email: contact.email,
    },
  };

  //latitude and longitude are only present if a google maps location
  //is selected. Not present in custom locations
  if (location.address.latitude && location.address.longitude) {
    calendarEvent.geo = {
      lat: location.address.latitude,
      lon: location.address.longitude,
    };
  }

  ics.createEvent(calendarEvent, (error, value) => {
    if (error) {
      console.log(error);
      return;
    }

    download(
      eventNameToFileName(event.name),
      value,
      "text/calendar;charset=utf8"
    );
  });
};
