import { firebaseReference } from "../firebase";
import { userHook } from "./userHooks";

function authProvider() {
  const provider = new firebaseReference.auth.OAuthProvider("microsoft.com");
  provider.setCustomParameters({
    tenant: "a2e7980c-11ea-4838-8f1a-2f497d8c4072",
  });
  provider.addScope("User.Read");
  return provider;
}

function signInWithRedirect() {
  return firebaseReference.auth().signInWithRedirect(authProvider());
}

async function updateUserDocument() {
  const updateUser = firebaseReference.functions().httpsCallable("updateUser");

  return updateUser({}).catch((error) => {
    // Log and continue
    console.error(error);
  });
}

export const useInternalUser = userHook({
  onSignedOut: signInWithRedirect,
  onRedirectResult: () => {
    return updateUserDocument();
  },
});
