/**
 * Formats any 10-digit phone number into domestic format: (ddd) ddd-dddd
 * @param value Any 10-digit phone number string
 * @return {string|null} The number formatted in "domestic" format: (ddd) ddd-dddd
 */
export function formatPhoneNumber(value) {
  const cleaned = ("" + value).replace(/\D/g, "");
  if (cleaned.length !== 10) {
    return cleaned;
  }
  const matches = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (matches == null) {
    return "";
  }
  return "(" + matches[1] + ") " + matches[2] + "-" + matches[3];
}
