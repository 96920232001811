import React from "react";
import Container from "react-bootstrap/Container";
import { isPast } from "date-fns";
import { useHistory, Redirect } from "react-router-dom";
import classes from "./EventRegistrationPage.module.css";
import { registrationStatus } from "../../../event";
import { useEventRegistrantSaver } from "../../hook/useEventRegistrantSaver";
import EventRegistrationForm from "../../component/EventRegistrationForm/EventRegistrationForm";
import { useLoadedUser } from "../../../auth";
import { isBlank } from "../../../form-validation/string";

function nameAndId(value) {
  const { id, name } = value;
  return {
    id,
    name,
  };
}

function userToFormValues(user) {
  const { firstName, lastName, email, phone, companyName, title } = user;
  return {
    firstName,
    lastName,
    email,
    phone,
    companyName,
    title,
  };
}

function externalUserFormValues(event, registration) {
  // The user is always anonymous
  const values = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    title: "",
    employeeContact: "",
    dietaryRestrictions: "",
    meals: [],
    ...registration,
  };

  const { meals: selectedMeals = [] } = values;
  const meals = (event.meals || []).map((meal) => {
    const { options = [] } = meal;
    const selectedMeal = selectedMeals.find((x) => x.id === meal.id);
    return {
      id: meal.id,
      selected: selectedMeal != null,
      option:
        selectedMeal != null
          ? selectedMeal.option?.id
          : options[0]
          ? options[0].id
          : undefined,
    };
  });

  return {
    ...values,
    meals,
  };
}

function internalUserFormValues(user, event, registration) {
  const {
    firstName = "",
    lastName = "",
    email = "",
    phone = "",
    companyName = "",
    title = "",
    dietaryRestrictions = "",
    employeeContact = "",
    flagFirstEvent = false,
    flagUnion = false,
    flagAgreeToTerms = false,
    meals: selectedMeals = [],
    workshops: selectedWorkshops = [],
  } = {
    ...userToFormValues(user),
    ...registration,
  };

  // Set these variables separately; there are situations when the registration group or subgroup is explicitly set to
  // null and are stored that way in Firestore.  For example, when a user is invited to an event that they haven't
  // registered for yet; in this case their group and subgroup property will be null.  Default values are not assigned
  // when destructuring a null property.
  const selectedGroup = registration.group || {};
  const selectedSubgroup = registration.subgroup || {};

  const { groups = [] } = event;
  let firstOpenSubgroup = {};
  const selectedGroupNoSubgroups = groups.find((group) => {
    if (group.id === selectedGroup.id && !group.subgroups) return true;
  });

  const firstOpenGroup =
    groups.find((group) => {
      if (!group.subgroups) return true;
      // If selected group has no subgroups, we will default to empty string for subgroups
      // instead of finding first open subgroup
      firstOpenSubgroup = selectedGroupNoSubgroups
        ? ""
        : group.subgroups.find((subgroup) => {
            return subgroup.registrationCount < subgroup.capacity;
          }) || {};
      return firstOpenSubgroup.id;
    }) || {};

  const group = selectedGroup?.id || firstOpenGroup?.id || "";
  const subgroup = selectedSubgroup?.id || firstOpenSubgroup?.id || "";

  const meals = (event.meals || []).map((meal) => {
    const { options = [] } = meal;
    const selectedMeal = selectedMeals.find((x) => x.id === meal.id);
    return {
      id: meal.id,
      selected: selectedMeal != null,
      option:
        selectedMeal != null
          ? selectedMeal.option?.id
          : options[0]
          ? options[0].id
          : undefined,
    };
  });

  const workshops = selectedWorkshops.map((workshop) => ({
    id: workshop.id,
  }));

  return {
    firstName,
    lastName,
    email,
    phone,
    companyName,
    title,
    dietaryRestrictions,
    employeeContact,
    flagFirstEvent,
    flagUnion,
    flagAgreeToTerms,
    meals,
    workshops,
    group,
    subgroup,
  };
}

function createRegistration(formValues, user, event) {
  const {
    email,
    group: groupId,
    subgroup: subgroupId,
    meals = [],
    workshops = [],
  } = formValues;

  const selectedMeals = meals
    .filter((meal) => meal.selected === true)
    .map((selectedMeal) => {
      const { id, name = "", options = [] } = event.meals.find(
        (x) => x.id === selectedMeal.id
      );

      return {
        id,
        name,
        option: selectedMeal.option
          ? options.find((x) => x.id === selectedMeal.option)
          : options.length > 0
          ? options[0]
          : null,
      };
    });

  const registration = {
    ...formValues,
    email,
    emailNormalized: email.toUpperCase(),
    meals: selectedMeals,
    workshops,
    id: event.registration?.id,
    status: registrationStatus.registered,
  };

  if (!user.isAnonymous) {
    registration.internalUserId = user.id;
    registration.internalResource = user;
  }

  if (!isBlank(groupId)) {
    const group = event.groups?.find((x) => x.id === groupId);
    registration.group = nameAndId(group);
    if (!isBlank(subgroupId)) {
      const subgroup = group.subgroups?.find((x) => x.id === subgroupId);
      registration.subgroup = nameAndId(subgroup);
    }
  }

  return registration;
}

function EventRegistrationPage({ event }) {
  const [
    saveRegistration,
    saveRegistrationResource,
  ] = useEventRegistrantSaver();
  const user = useLoadedUser();
  const history = useHistory();

  // Redirect if event or deadline have passed.
  if (isPast(event.registrationDeadline)) {
    return <Redirect to={`/events${window.location.search}`} />;
  }

  async function onSubmit(values, e) {
    saveRegistration(event, createRegistration(values, user, event))
      .then(({ status: savedStatus, id }) => {
        // Code is generic so it can be shared across registration sites
        const regType =
          event.registration?.status === savedStatus
            ? "registration-updated"
            : savedStatus;

        const queryString = user.isAnonymous ? `?registrationId=${id}` : "";

        history.push(`/events/${event.id}/${regType}${queryString}`);
      })
      .catch((error) => console.error(error));
  }

  return (
    <Container>
      <h1 className={classes.heading}>
        Registering for <strong>{event.name}</strong>
      </h1>
      <EventRegistrationForm
        {...{ event, onSubmit }}
        defaultValues={
          user.isAnonymous
            ? externalUserFormValues(event, event.registration)
            : internalUserFormValues(user, event, event.registration)
        }
        submitting={saveRegistrationResource.status === "loading"}
      />
      {/* TODO handle form submit errors */}
    </Container>
  );
}

export default EventRegistrationPage;
