import React from "react";
import { format } from "date-fns-tz";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons/faCalendarAlt";
import styles from "./EventDateAndTime.module.css";

function EventDateAndTime({ event }) {
  const { startsAt } = event;
  if (startsAt == null) {
    return <i>Not scheduled</i>;
  }
  return (
    <div className={styles.alignLeft}>
      <FontAwesomeIcon icon={faCalendarAlt} className={styles.calendarIcon} />
      <span className={styles.eventDate}>
        {format(startsAt, "MMM dd, yyyy")}
      </span>
      <span className={styles.eventTime}>{format(startsAt, "h:mm aa z")}</span>
    </div>
  );
}

export default EventDateAndTime;
