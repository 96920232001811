import React from "react";
import Container from "react-bootstrap/Container";
import {
  EventList,
  Heading,
  Loading,
  NotFound,
  useLoadedUser,
  useInternalReadonlyEvents,
} from "@sempra-event-registration/common";
import classes from "./EventsPage.module.css";

function EventsPage() {
  const user = useLoadedUser();
  const { status, value: events, error } = useInternalReadonlyEvents(user);

  if (status === "loading" || status === "empty") return <Loading />;
  if (status === "error") {
    console.error("Failed to get events", error);
    return <NotFound />;
  }

  return (
    <React.Fragment>
      <Container>
        <Heading underlined className={classes.heading}>
          <h1>Upcoming Events</h1>
        </Heading>
      </Container>
      <EventList {...{ events }} />
    </React.Fragment>
  );
}

export default EventsPage;
